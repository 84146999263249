<template>
    <body class="poster">
    <div class="login-container" id="myDIV">
        <div class="login_img">
            <img src="../assets/img/logo.jpg">
        </div>
        <div class="login_form">
            <div>
                <div class="login_title">
                    <h2>Biwin投资管理平台</h2>
                </div>
                <el-form v-loading="loading" ref="ruleForm" :rules="rules" :model="ruleForm">
                    <el-form-item label="" prop="roleFlag" class="formClass">
                        <el-select v-model="ruleForm.roleFlag" placeholder="请选择登录角色" clearable>
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="" prop="adminName" class="formClass">
                        <el-input v-model="ruleForm.adminName" prefix-icon="el-icon-user" placeholder="请输入用户名"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="password" class="formClass">
                        <el-input type="password" v-model="ruleForm.password" prefix-icon="el-icon-s-goods" clearable
                                  placeholder="请输入密码"></el-input>
                    </el-form-item>
                </el-form>

                <div style="text-align: center;padding: 10px 0">
                    <el-button type="primary" @click="login('ruleForm')">确认登录</el-button>
                </div>
            </div>


        </div>

        <!--        <iframe width="100%" height="100%" src="https://continue-2000.github.io/Echarts-visualization-display-board/"></iframe>-->
    </div>
    </body>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                loading: false,
                ruleForm: {},

                value: null,
                rules: {
                    roleFlag: [
                        {required: true, message: '请选择登录角色', trigger: 'blur'}
                    ],
                    adminName: [
                        {required: true, message: '请输入用户名', trigger: 'blur'},
                        {min: 5, max: 10, message: '长度5-10位', trigger: 'blur'}
                    ],

                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 5, max: 10, message: '长度5-10位', trigger: 'blur'}
                    ]
                },
                options: [
                    {
                        label: '管理员',
                        value: 1
                    },
                    {
                        label: '代理商',
                        value: 2
                    }
                ]
            }
        },
        created() {
            //回车键事件
            this.enterKey();
        },
        methods: {
            //添加回车键事件
            enterKey() {
                let t = this;
                let key = null;
                document.onkeydown = function (e) {
                    if (window.event == undefined) {
                        key = e.keyCode;
                    } else {
                        key = window.event.keyCode;
                    }
                    //enter的ASCII码是13
                    if (key == 13) {
                        t.login('ruleForm');//t.登录方法
                    }
                }
            },
            login(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.axios.post("/admin/login?adminName=" + this.ruleForm.adminName + "&password=" + this.ruleForm.password + "&roleFlag=" + this.ruleForm.roleFlag).then(resp => {
                            if (resp.data.code == 200) {
                                this.$message({
                                    message: resp.data.message,
                                    type: 'success'
                                });
                                //保存token键
                                sessionStorage.setItem("tokenName", resp.data.data.tokenInfo.tokenName)
                                //保存token值
                                sessionStorage.setItem("tokenValue", resp.data.data.tokenInfo.tokenValue);
                                sessionStorage.setItem("realName", resp.data.data.admin.realName);
                                //保存userId
                                sessionStorage.setItem('userId', resp.data.data.admin.id);
                                //保存登陆角色标识
                                sessionStorage.setItem("roleFlag", this.ruleForm.roleFlag);

                                //跳转到主页
                                this.$router.replace("/main");
                            }
                        }).finally(() => {
                            this.loading = false;
                        })
                    } else {
                        //this.$message.warning("请输入账号和密码！")
                    }
                })

            },

        }
    }
</script>

<!--上下浮动-->
<style>
    #myDIV {
        animation: mymove 1.5s infinite;
        -webkit-animation: mymove 1.5s infinite;
    }

    @keyframes mymove {
        0% {
            transform: translate(0px, 0px);
        }
        50% {
            transform: translate(0px, -10px);
        }
        100% {
            transform: translate(0px, 0px);
        }

    }

    /*Safari 和 Chrome:*/
    @-webkit-keyframes mymove {
        0% {
            transform: translate(0px, 0px);
        }
        50% {
            transform: translate(0px, -10px);
        }
        100% {
            transform: translate(0px, 0px);
        }
    }
</style>

<style lang="scss" scoped>
    .poster {
        background: url("../assets/img/bg.jpg") no-repeat;
        background-position: center;
        height: 100%;
        width: 100%;
        background-size: cover;
        position: fixed;
    }

    body {
        margin: 0px;
        padding: 0;
    }

    .login_title {
        // padding-top: 10px;
        font-family: 楷体;
        text-align: center;
    }

    .login-container {
        border-radius: 30px;
        background-clip: padding-box;
        margin: 200px auto;
        width: 400px;
        padding: 0px 35px 15px 35px;

        background: #ffffff;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 25px #cac6c6;

        .login_form {
            margin-top: 70px;
            display: flex;
            justify-content: center;
        }

        .login_img {
            border-radius: 50%;
            border: 1px solid #eeeeee;
            width: 100px;
            height: 100px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
            margin: -65px auto;
            background-color: #ffffff;

            img {
                height: 100%;
                width: 100%;
                border-radius: 50%;
                background-color: #eeeeee;
            }
        }
    }

    .loginButton {
        margin-top: 15px;
    }

    .formClass {
        margin-top: 30px;
    }

    .el-select {
        width: 350px;
    }

    .el-input {
        width: 350px;
    }
</style>
